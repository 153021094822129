<template>
  <v-container fluid height="100%" class="mt-6">
    <v-row>
      <v-col class="text-center">
        <h1>Nada por aqui...</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="text-center">
        <img
          src="@/assets/images/404_2.png"
          alt="404"
          class="responsive-img"
          v-if="darkTheme"
        />
        <img src="@/assets/images/404.png" alt="404" class="responsive-img" v-else />
      </v-col>
    </v-row>
    <v-row>
      <v-col class="text-center">
        <v-btn link to="/">Regresar</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState(["darkTheme"]),
  },
};
</script>
